import React, { useContext } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

import CheckIcon from '@mui/icons-material/Check';
import { Grid, Radio } from '@mui/material';

import {
  HeadLabel,
  BodyLabel,
  HeadGrid,
  BodyGrid,
  RowGrid,
  Typography,
} from 'components/parts/atoms';

import { getSelectText } from 'utils/selectUtils';

import { CustomerEditContext } from '../..';
import { CustomerEdit } from '../../types';

// 基本情報
const CustomerEditBasic: React.VFC = () => {
  // フォームデータ
  const formContext = useFormContext<CustomerEdit>();
  const data = useContext(CustomerEditContext);
  const master = data.selectBoxData;

  /** 担当者(当社)のデータ変更 */
  // ステート
  const chargesOurArray = useFieldArray({
    control: formContext.control,
    name: 'chargesOur',
  });

  // メイン担当者の情報
  const chargesOur = formContext.getValues('chargesOur');
  const mainChargeIndex = chargesOur.findIndex((item) => item.mainCharged);

  /** 担当者(お客様)のデータ変更 */
  // ステート
  const chargesYourArray = useFieldArray({
    control: formContext.control,
    name: 'chargesYour',
  });
  // 追加ボタンによる追加処理
  const onAddYourCharge = () => {
    chargesYourArray.append({
      customerChargeId: -1,
      seikyuusakiFlg: false,
      customerChargeName: '',
      belongPost: '',
      emailAddress: '',
    });
  };

  return (
    <>
      <RowGrid columns={18}>
        <HeadGrid xs={3}>
          <HeadLabel>部門コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.customerSectionCd'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>得意先 コード</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.customerCd'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>
            JOB No.用 <br />
            コード
          </HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.jobNoCd'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={1}>
          <HeadLabel>削除</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <Controller
            name='customerEditSingleInfo.deleteFlg'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value == true ? '削除' : ''}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>グループ</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={1}>
          <Controller
            name='customerEditSingleInfo.customerGroup'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>得意先正式名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name='customerEditSingleInfo.customerFormalName'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>支社支店区分</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={2}>
          <Controller
            name='customerEditSingleInfo.branchFlg'
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{field.value === true ? '九州事業所' : '本社'}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>得意先表示名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name='customerEditSingleInfo.customerName'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={4}>
          <HeadLabel>売上部門グループ</HeadLabel>
        </HeadGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>得意先カナ名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={6}>
          <Controller
            name='customerEditSingleInfo.customerKanaName'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.sectionPeculiarInfo'
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.sections)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid
          xs={2}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <HeadLabel>担当者(当社)</HeadLabel>
          <Typography sx={{ fontSize: '0.6rem' }}>※メイン担当は1名のみ設定可能です</Typography>
        </HeadGrid>
        <Grid xs={10}>
          <RowGrid columns={1}>
            <HeadGrid xs={0.055} sx={{ borderBottom: '1px solid #000', justifyContent: 'left' }}>
              <HeadLabel>
                メイン<br></br>担当
              </HeadLabel>
            </HeadGrid>
            <HeadGrid xs={0.945} sx={{ borderBottom: '1px solid #000', justifyContent: 'left' }}>
              <HeadLabel>担当者名</HeadLabel>
            </HeadGrid>
          </RowGrid>
          <Grid item xs={12}>
            {chargesOurArray.fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <RowGrid columns={1}>
                    <BodyGrid xs={0.055}>
                      {mainChargeIndex === Number(index) && <CheckIcon />}
                    </BodyGrid>
                    <BodyGrid xs={0.945}>
                      <Controller
                        name={`chargesOur.${index}.fullName`}
                        control={formContext.control}
                        render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                      />
                    </BodyGrid>
                  </RowGrid>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>郵便番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.zipCd'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>運送会社</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.transporterId'
            control={formContext.control}
            render={({ field }) => (
              <BodyLabel>{getSelectText(field.value, master.transporters)}</BodyLabel>
            )}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={1}>
          <HeadLabel>
            住所
            <br />
            (請求先)
          </HeadLabel>
        </HeadGrid>
        <Grid item xs={11}>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>
              <HeadLabel>都道府県</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.prefecturesId'
                control={formContext.control}
                render={({ field }) => (
                  <BodyLabel>{getSelectText(field.value, master.prefectures)}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>
              <HeadLabel>市区町村</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.address1'
                control={formContext.control}
                render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>
              <HeadLabel>丁目･番地</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.address2'
                control={formContext.control}
                render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={11}>
            <HeadGrid xs={1}>
              <HeadLabel>ビル名</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={10}>
              <Controller
                name='customerEditSingleInfo.buildingName'
                control={formContext.control}
                render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
              />
            </BodyGrid>
          </RowGrid>
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>電話番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.tel'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
        <HeadGrid xs={2}>
          <HeadLabel>FAX番号</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={4}>
          <Controller
            name='customerEditSingleInfo.fax'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>代表者名</HeadLabel>
        </HeadGrid>
        <BodyGrid xs={10}>
          <Controller
            name='customerEditSingleInfo.representativeName'
            control={formContext.control}
            render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
          />
        </BodyGrid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>担当者(お客様)</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={10}>
            <HeadGrid xs={1} sx={{ justifyContent: 'center' }}>
              <HeadLabel>請求先</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
              <HeadLabel>担当者名</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
              <HeadLabel>所属部署</HeadLabel>
            </HeadGrid>
            <HeadGrid xs={3} sx={{ justifyContent: 'center' }}>
              <HeadLabel>MailAddress</HeadLabel>
            </HeadGrid>
          </RowGrid>
          {chargesYourArray.fields.map((item, index) => {
            return (
              <div key={item.id}>
                <RowGrid columns={10}>
                  <BodyGrid xs={1}>
                    <Controller
                      name={`chargesYour.${index}.seikyuusakiFlg`}
                      control={formContext.control}
                      render={({ field }) => (
                        <BodyLabel>{field.value == true ? '●' : ''}</BodyLabel>
                      )}
                    />
                  </BodyGrid>
                  <BodyGrid xs={3}>
                    <Controller
                      name={`chargesYour.${index}.customerChargeName`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                  <BodyGrid xs={3}>
                    <Controller
                      name={`chargesYour.${index}.belongPost`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                  <BodyGrid xs={3}>
                    <Controller
                      name={`chargesYour.${index}.emailAddress`}
                      control={formContext.control}
                      render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
                    />
                  </BodyGrid>
                </RowGrid>
              </div>
            );
          })}
        </Grid>
      </RowGrid>

      <RowGrid columns={12}>
        <HeadGrid xs={2}>
          <HeadLabel>東洋インキG情報</HeadLabel>
        </HeadGrid>
        <Grid item xs={10}>
          <RowGrid columns={10}>
            <HeadGrid xs={2}>
              <HeadLabel> Σコード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkCustomerCd'
                control={formContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>
                )}
              />
            </BodyGrid>
            <HeadGrid xs={2}>
              <HeadLabel>倉庫コード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkStoreHouseCd'
                control={formContext.control}
                render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
              />
            </BodyGrid>
          </RowGrid>
          <RowGrid columns={10}>
            <HeadGrid xs={2}>
              <HeadLabel>インキ用仕入先コード</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkSupplierCd'
                control={formContext.control}
                render={({ field }) => <BodyLabel>{field.value}</BodyLabel>}
              />
            </BodyGrid>
            <HeadGrid xs={2}>
              <HeadLabel>インキフラグ</HeadLabel>
            </HeadGrid>
            <BodyGrid xs={3}>
              <Controller
                name='customerEditSingleInfo.inkFlg'
                control={formContext.control}
                render={({ field }) => (
                  <BodyLabel>{field.value === 0 ? '' : field.value}</BodyLabel>
                )}
              />
            </BodyGrid>
          </RowGrid>
        </Grid>
      </RowGrid>
    </>
  );
};

export default CustomerEditBasic;
